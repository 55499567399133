export function bitfRoundToTwoDecimals(value: number): number {
  return Math.round(value * 100) / 100;
}

export const bitfFormatNumber = (value, separator) => {
  if (!value) {
    return value;
  }
  value = String(value);
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const bitfFilterDigits = (value: string): string => {
  value = String(value);
  return value.replace(/\D+/g, '');
};

export function nullOrNaN(value: number): boolean {
  return value == null || Number.isNaN(value);
}

export function scaleFormatter(value: number): string {
  if (roundNumber(value, 10) < 1000) {
    return value.toFixed(1).toString();
  } else if (roundNumber(value, 1000) >= 1000 && roundNumber(value, 1000) < 1000000) {
    return ((Math.sign(value) * Math.abs(value)) / 1000).toFixed(1).toString() + ' K';
  } else if (roundNumber(value, 1000000) >= 1000000) {
    return ((Math.sign(value) * Math.abs(value)) / 1000000).toFixed(1).toString() + ' M';
  }
}

export function roundNumber(value: number, unit: number): number {
  return Math.abs(Math.round(value / unit) * unit);
}
