import { OnInit, Input, Output, EventEmitter, Injector, OnChanges, Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { debounce, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { of, timer } from 'rxjs';

import { BitfFormItem } from '@bitf/core/models/bitf-form-item.model';
import { BitfFormControl } from '@bitf/core/form';

import { IBitfUiRoleManagerConfig } from '@interfaces';

import { ERoleMode, EBitfFormControlValidatorsKeys } from '@enums';
import { UiRoleManagerService } from '@services';

import { IBitfFormItemComponentConfig } from './bitf-form-item.interface';
import { BitfOption } from '../../../models';

@Component({
  selector: 'bitf-form-item',
  template: '',
  standalone: false,
})
export abstract class BitfFormItemComponent implements OnInit, OnChanges {
  @Input() formItem: BitfFormItem;

  @Input() form: UntypedFormGroup | UntypedFormArray;

  @Input() formItemControlName: string | number;

  @Input() componentConfig: IBitfFormItemComponentConfig = {};

  @Input() showLoader: boolean;

  @Input() minDateField: string;

  @Input() multiple: boolean;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  hiddenUIRole: IBitfUiRoleManagerConfig;

  formControl: BitfFormControl = new BitfFormControl('');
  savedFormControlValue: any;

  uiRoleManagerService: UiRoleManagerService;
  translateService: TranslateService;

  constructor(public injector: Injector) {
    this.uiRoleManagerService = injector.get<UiRoleManagerService>(UiRoleManagerService);
    this.translateService = injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    this.buildFormItem();
  }

  ngOnChanges(changes): void {
    if (
      changes.formItem &&
      !changes.formItem.firstChange &&
      changes.formItem.currentValue !== changes.formItem.previousValue
    ) {
      this.buildFormItem();
    }

    const shoudCheckIsDisabled =
      changes.formItem && !changes.formItem.firstChange && changes.formItem.currentValue;
    if (shoudCheckIsDisabled) {
      this.onDisabledChange();
    }
  }

  buildFormItem() {
    if (!this.formItem) {
      return;
    }

    if (this.formItem.metaData?.componentConfig) {
      this.componentConfig = this.formItem.metaData.componentConfig;
    }

    if (this.formItem.uiRole) {
      Object.assign(this.componentConfig, { uiRole: this.formItem.uiRole });
    }

    if (this.componentConfig && this.componentConfig.uiRole) {
      this.hiddenUIRole = {
        ...this.componentConfig.uiRole,
        mode: ERoleMode.HIDDEN,
      };
    }

    if (this.form instanceof UntypedFormArray) {
      this.formControl = this.form.at(this.formItemControlName as number) as BitfFormControl;
    } else {
      this.formControl = this.form.get(this.formItemControlName as string) as BitfFormControl;
    }

    this.initForItem();
    this.initValidation();
    this.setDefault();
    this.onDisabledChange();
  }

  onSelectionChange(event) {
    this.selectionChange.emit(event);
    this.componentConfig.autocompleteData = this.formItem.options || [];
  }

  canAction(): boolean {
    if (this.componentConfig.uiRole) {
      return this.uiRoleManagerService.canI(this.componentConfig.uiRole.action);
    }
    return true;
  }

  protected initForItem() {
    if (this.minDateField) {
      this.form.get(this.minDateField).valueChanges.subscribe(value => {
        this.reloadDate(new Date(value));
      });
    }

    if (this.formItem.type === 'autocomplete' || this.formItem.type === 'autocomplete-chips') {
      this.componentConfig.autocompleteData = this.formItem.options || [];
      this.formControl.valueChanges
        .pipe(
          debounce(() => timer(500)),
          distinctUntilChanged(),
          filter(
            value =>
              value?.length >= (this.componentConfig?.autocompleteMinLength || 3) || value?.length === 0
          )
        )
        .pipe(
          tap(result => this.valueChanges.emit(result)),
          switchMap(result => {
            // if result is a string, the user is typing, otherwise it's a selection
            if (typeof result === 'string') {
              if (this.componentConfig.autocompleteRequest) {
                // server side filtering
                return this.componentConfig.autocompleteRequest(result);
              } else {
                // client side filtering
                return of(
                  this.formItem.options.filter(option =>
                    option.label?.toLocaleLowerCase().includes(result.toLocaleLowerCase())
                  )
                );
              }
            } else {
              return of(this.formItem.options || []);
            }
          }),
          tap((options: BitfOption[]) => {
            this.componentConfig.autocompleteData = options;
          })
        )
        .subscribe();
    }
  }

  protected initValidation() {
    this.formItem.validators = this.formItem.validators ? this.formItem.validators : [];

    // NOTE: this is left for backwards compatibility, validators will be specified in the
    // valiators: [{key: EBitfFormControlValidatorsKeys, params: any }] prop
    if (this.formItem.isRequired) {
      this.formItem.validators.push({ key: EBitfFormControlValidatorsKeys.required });
    }

    if (this.formItem.validators && this.formItem.validators.length) {
      this.formControl.setDynamicValidator(this.formItem.validators);
    }
  }

  protected setDefault() {
    if (
      this.formItem.type !== 'select' &&
      this.formItem.type !== 'multipleSelect' &&
      this.formItem.type !== 'tree' &&
      this.formItem.default !== null &&
      !this.formItem.default !== undefined &&
      this.formControl.value === null &&
      this.formItem.setDefault
    ) {
      this.formControl.setValue(this.formItem.default);
    }
  }

  protected onDisabledChange() {
    if (!this.formControl) {
      return;
    }

    if (this.formItem.isDisabled && !this.formControl.disabled) {
      this.savedFormControlValue = this.formControl.value;
      this.formControl.disable();
    } else if (!this.formItem.isDisabled && this.formControl.disabled) {
      this.formControl.enable();
      this.formControl.setValue(this.savedFormControlValue);
    }
  }

  protected reloadDate(startDate: Date) {
    const currentDate = this.formControl.value;
    if (startDate > new Date(currentDate)) {
      this.formControl.patchValue(startDate);
    }
  }
}
