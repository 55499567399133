import { Component, ElementRef, Injector, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDatepicker } from '@angular/material/datepicker';
import { BitfOption } from '@common/libs/bitforce/core/models';
import { BitfFormItemComponent } from '../../bitf-form-item.component';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'bitf-mat-form-item',
  templateUrl: './bitf-mat-form-item.component.html',
  styleUrls: ['./bitf-mat-form-item.component.scss'],
  standalone: false,
})
export class BitfMatFormItemComponent extends BitfFormItemComponent implements OnInit {
  @Input() matFormFieldClass: string;

  @Input() hourOfTheDayMs: number;

  @ViewChild('pickerDate', { static: false })
  pickerDate: MatDatepicker<Date>;

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  chips: string[] = [];
  chipsObj: any[] = [];

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.formItem.type === 'autocomplete-chips' || this.formItem.type === 'autocomplete') {
      this.initAutocomplete();

      this.formControl.valueChanges.subscribe(value => {
        // Subscribe to form control value changes in order to re-initialize autocomplete data each times the
        // form control value changes (e.g. when the form has been updated with the server response data).
        // The form control value must be mapped to BitfOption to be able to match it with autocomplete data.
        if ((Array.isArray(value) && value.length === 0) || !value) {
          this.chips = [];
          this.chipsObj = [];
        }

        if (
          Array.isArray(value) &&
          value.some(v => !(v instanceof BitfOption)) &&
          !(value instanceof BitfOption) &&
          !(typeof value === 'string')
        ) {
          this.initAutocomplete();
        }
      });
    }
  }

  onAutocompleteBlur() {
    // NOTE: this is needed to reset the form control value when the user types a value
    // that is not in the autocomplete list:BitfOption[]
    if (typeof this.formControl.value === 'string') {
      this.formControl.patchValue(null);
    }
  }

  private initAutocomplete() {
    // map current form values to BitfOption(s)
    if (Array.isArray(this.formControl.value)) {
      if (this.componentConfig.useObject) {
        this.formControl.setValue(
          this.formControl.value.map(value => this.getBitfOption(value)),
          { emitEvent: false }
        );
      }
    } else if (this.formControl.value) {
      if (this.componentConfig.useObject) {
        this.formControl.setValue(this.getBitfOption(this.formControl.value), { emitEvent: false });
      }
      this.addAutocompleteData(this.formControl.value);
    }

    if (this.formItem.type === 'autocomplete-chips') {
      this.chipsObj = [];
      this.chips = [];

      // create chips
      if (this.formControl.value) {
        const autocompleteChipsFormValue = Array.isArray(this.formControl.value)
          ? this.formControl.value
          : [this.formControl.value];

        this.chipsObj = autocompleteChipsFormValue || [];
        this.chips = autocompleteChipsFormValue.filter(val => val && val['label'])?.map(val => val['label']);
      }
    }
  }

  isChipSelected(chipValue: number | string): boolean {
    const values = this.formControl?.value;
    if (values?.length) {
      return values.includes(chipValue);
    }
    return false;
  }

  private getBitfOption(value): BitfOption {
    return new BitfOption({
      // The form control value is a BitfOption or an array of BitfOption, we spread values
      // for retrocompatibility with old bitfcore installations.
      ...(this.componentConfig.useObject && value),
      id: this.componentConfig.useObject ? value.id : value,
      label: this.componentConfig.useObject
        ? value[this.componentConfig.autocompleteModelMapKey] || value['label']
        : value,
    });
  }

  private addAutocompleteData(value) {
    const item = this.componentConfig.autocompleteData.find(
      _item => _item.id === (this.componentConfig.useObject ? value.id : value)
    );

    if (!item && this.componentConfig.useObject) {
      this.componentConfig.autocompleteData.push(this.getBitfOption(value));
    }
  }

  get filteredAutocompleteChipsData() {
    return this.componentConfig.autocompleteData.filter(x =>
      this.componentConfig.useObject
        ? !this.chipsObj.map(c => c.id).includes(x['id'])
        : !this.chips.includes(x['label'])
    );
  }

  displayFn(option: any): string {
    if (option) {
      const item = this.componentConfig.autocompleteData.find(
        _item => _item.id === (this.componentConfig.useObject ? option.id : option)
      );
      return item && item['label'];
    }
    return '';
  }

  reloadDate(startDate: Date) {
    const currentDate = this.formControl.value;
    if (startDate > new Date(currentDate)) {
      this.formControl.patchValue(startDate);
    }
  }

  toggleDatePicker() {
    if (this.canAction() && !this.formItem.isDisabled) {
      this.pickerDate.open();
    }
  }

  onDateChange() {
    const hourOfTheDayMs = this.formItem.metaData?.hourOfTheDayMs || this.hourOfTheDayMs;
    if (hourOfTheDayMs) {
      const date: Date = this.formControl.value;
      date.setHours(0, 0, 0, 0);
      this.formControl.patchValue(new Date(date.getTime() + hourOfTheDayMs), {
        emitEvent: false,
      });
    }
  }

  deleteValue() {
    this.formControl.markAsDirty();
    this.formControl.patchValue('');
  }

  removeChip(index) {
    this.chips.splice(index, 1);
    this.chipsObj.splice(index, 1);
    this.formControl.markAsDirty();
    this.formControl.patchValue(this.chipsObj);
    this.selectionChange.emit(this.chipsObj);
  }

  onChipsSelectionChange(event: MatAutocompleteSelectedEvent): void {
    this.chips.push(event.option.viewValue);
    this.chipsObj.push(event.option.value);
    this.formControl.patchValue(this.chipsObj);
    this.chipInput.nativeElement.value = '';
    this.selectionChange.emit(this.chipsObj);
  }

  onMatChipInputTokenEnd(event: MatChipInputEvent): void {
    // NOTE: if the user type a valid option that is converted
    // to a chip, so in case there is a string as value, on the
    // blur event that string will be deleted and replaced with the chipsObj
    // if there are any
    this.formControl.patchValue(this.chipsObj);
    this.chipInput.nativeElement.value = '';
  }
}
